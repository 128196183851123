type Option = {
    id: string,
    name: string,
};

export const Selector = ({ label, onChange, option, options }: { label: string, onChange: any, option: Option, options: Option[] }) => {
    return <div className="form-control w-full">
        <span className="label-text">{label}</span>
        <select className="select bg-white select-bordered w-full" value={option.id} onChange={(event) => onChange(event.target.value)}>
            {
                options.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>)
                )
            }
        </select>
    </div>

};