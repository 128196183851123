import { LoadingScreen } from '../../../components/loading-screen';
import { useCognito } from '../../../providers/cognito.provider';
import { useEffect } from 'react';

export function LogoutPage() {
  const { logout } = useCognito();

  useEffect(() => {
    logout();
  }, [logout]);

  return <LoadingScreen />;
}
