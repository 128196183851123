export const languages = [
    { id: 'ro', name: 'Română' },
    { id: 'en', name: 'English' },
];


export const conversationTypes = [
    { id: 'StoryWithQuestions', name: 'Story with Questions' },
    { id: 'StoryWithDialog', name: 'Story with Dialog' },
];

export const chatModelNames = [
    { id: 'gpt-3.5-turbo', name: 'gpt-3.5-turbo' },
    { id: 'gpt-4', name: 'gpt-4' },
    { id: 'gpt-4-turbo-preview', name: 'gpt-4-turbo-preview' },
];


export const AuthRoutesPaths = {
    LandingPage: '/',
    Login: '/login.html',
    ForgotPassword: '/forgot-password.html',
    ChangePassword: '/change-password',
    LoginPassword: '/login-password',
    OauthToken: '/oauth/token',
    Logout: '/logout.html',
    Signup: '/signup.html',
};
