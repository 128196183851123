import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { HeaderMenu } from '../components/header-menu';

export function LandingPage() {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet>
                <title>Tali - Librarie de Povesti</title>
                <meta
                    name="description"
                    content="Tali"
                />
            </Helmet>


            <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
                <div className="mx-auto max-w-screen-xl text-center">
                    <a href="/" className="flex items-center justify-center">
                        <span className="self-center hidden md:block sm:s ml-2 text-sm md:text-lg font-semibold whitespace-nowrap dark:text-white">
                            Tali
                        </span>
                    </a>
                    <ul className="mt-6 flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
                        {/* link to privacy policy, terms and conditons and cookie policy*/}
                        <li>
                            <a
                                href="/privacy-policy.html"
                                className="mr-4 hover:underline md:mr-6"
                            >
                                Privacy Policy
                            </a>
                        </li>

                        <li>
                            <a
                                href="/terms-and-conditions.html"
                                className="mr-4 hover:underline md:mr-6"
                            >
                                Terms and Conditions
                            </a>
                        </li>

                        <li>
                            <a
                                href="/cookie-policy.html"
                                className="mr-4 hover:underline md:mr-6"
                            >
                                Cookie Policy
                            </a>
                        </li>

                    </ul>
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © 2024{' '}
                        <a href="#" className="hover:underline">
                            Tali
                        </a>
                        . All Rights Reserved.
                    </span>
                </div>
            </footer>
        </div>
    );
}

declare global {
    interface Window {
        hsConversationsSettings: Record<string, any>;
        hsConversationsOnReady: Array<() => void>;
        HubSpotConversations: {
            on: any;
            off: any;
            widget: {
                status: () => { loaded: boolean; pending: boolean };
                load: (params?: { widgetOpen: boolean }) => void;
                remove: () => void;
                open: () => void;
                close: () => void;
                refresh: (openToNewThread?: boolean) => void;
            };
        };
    }
}
