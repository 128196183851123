import { useEffect, useRef } from 'react';


export const AudioPlayer = (props: { url?: string, onEnded?: any }) => {
    const audioRef = useRef<any>([]);


    useEffect(() => {
        if (props.url) {
            audioRef.current?.play().catch((e: Error) => {
                console.error(e);
            });
        }

        if (audioRef && audioRef.current) {
            // @ts-ignore
            audioRef.current.scrollTop = audioRef.current.scrollHeight;
        }

    }, [audioRef]);


    if (!props.url) {
        return props.onEnded()
    }

    return <div style={{ textAlign: 'center' }}>
        <audio
            ref={audioRef}
            className="m-auto"
            controls={true}
            src={props.url}
            autoPlay={true}
            onEnded={props.onEnded}
        />
    </div>
}
