/* eslint-disable @typescript-eslint/dot-notation */
import React, {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  useEffect,
} from 'react';
import { Amplify, Hub, ICredentials } from '@aws-amplify/core';
import {
  Auth,
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from '@aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
// import { Mixpanel } from '../../common/mixpanel';
import * as process from 'process';
import { AuthRoutesPaths } from '../constants';

let authDomain = process.env['REACT_APP_IDP_DOMAIN'];

try {
  Amplify.configure({
    Auth: {
      region: process.env['REACT_APP_USER_POOL_REGION'],
      userPoolId: process.env['REACT_APP_USER_POOL_ID'],
      userPoolWebClientId: process.env['REACT_APP_USER_POOL_CLIENT_ID'],
      cookieStorage: {
        domain: window.location.hostname,
        path: '/',
        expires: 7,
        secure: false,
      },
      oauth: {
        domain: process.env['REACT_APP_IDP_DOMAIN'],
        redirectSignIn: `${window.location.origin}${AuthRoutesPaths.OauthToken}`,
        redirectSignOut: `${window.location.origin}${AuthRoutesPaths.Login}`,
        responseType: 'code',
      },
    },
  });
} catch (e) {
  console.error(e);
}

interface CognitoContextType {
  currentUser: CognitoUser | null;
  loading: boolean;
  error: string;
  setError: (error: string) => void;
  isPasswordChallenge: boolean;

  login: (input: { username: string; password: string }) => Promise<void>;
  signup: (input: { username: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  forgotPasswordRequest: (email: string) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    password: string
  ) => Promise<string | void>;
  changePassword: (
    user: CognitoUser | null,
    prevPassword: string,
    newPassword: string
  ) => Promise<void | 'SUCCESS'>;
  createPassword: (user: CognitoUser | null, password: string) => Promise<void>;

  federatedSignIn: (
    provider: CognitoHostedUIIdentityProvider
  ) => Promise<void | ICredentials>;
}

const CognitoContext = createContext<CognitoContextType>(
  {} as CognitoContextType
);

export const CognitoProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  let navigate = useNavigate();

  const [currentUser, innerSetCurrentUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { search } = useLocation();

  const setCurrentUser = (input: CognitoUser | null) => {
    innerSetCurrentUser(input);
    // if (input) {
    //   Mixpanel.identify(input.getUsername());
    // } else {
    //   Mixpanel.reset();
    // }
  };

  useEffect(() => {
    Hub.listen('auth', (message) => {
      if (message.payload.event === 'signIn') {
        setCurrentUser(message.payload.data);
      } else if (message.payload.event === 'signOut') {
        setCurrentUser(null);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(setCurrentUser)
      .catch(() => setCurrentUser(null))
      .finally(() => setLoading(false));
  }, []);

  const login = useCallback((input: { username: string; password: string }) => {
    setLoading(true);
    setError('');

    return Auth.signIn(input)
      .then(setCurrentUser)
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, []);

  const signup = useCallback(
    (input: { username: string; password: string }) => {
      setLoading(true);
      setError('');

      return Auth.signUp({
        ...input,
        autoSignIn: {
          enabled: true,
        },
      })
        .then((response) => {
          setCurrentUser(response.user);
        })
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    },
    []
  );

  const federatedSignIn = useCallback(
    (provider: CognitoHostedUIIdentityProvider) => {
      setLoading(true);
      setError('');

      return Auth.federatedSignIn({ provider })
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    },
    []
  );

  const logout = useCallback(() => {
    setLoading(true);
    setError('');

    return Auth.signOut()
      .catch((error) => setError(error.message))
      .finally(() => {
        setLoading(false);
        navigate(AuthRoutesPaths.LandingPage);
      });
  }, [navigate]);

  const forgotPasswordRequest = useCallback((email: string) => {
    setLoading(true);
    setError('');

    return Auth.forgotPassword(email)
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, []);

  const forgotPasswordSubmit = useCallback(
    (username: string, code: string, password: string) => {
      setLoading(true);
      setError('');

      return Auth.forgotPasswordSubmit(username, code, password)
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    },
    []
  );

  const changePassword = useCallback(
    (user: CognitoUser | null, prevPassword: string, newPassword: string) => {
      setLoading(true);
      setError('');

      return Auth.changePassword(user, prevPassword, newPassword)
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    },
    []
  );

  const createPassword = useCallback(
    (user: CognitoUser | null, password: string) => {
      setLoading(true);
      setError('');

      return Auth.completeNewPassword(user, password)
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    },
    []
  );

  const value = useMemo(
    () => ({
      currentUser,
      loading,
      error,
      setError,
      // Amplify types are wrong - https://github.com/aws-amplify/amplify-js/issues/4927
      isPasswordChallenge:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currentUser?.challengeName === 'NEW_PASSWORD_REQUIRED',

      login,
      signup,
      logout,
      forgotPasswordRequest,
      forgotPasswordSubmit,
      changePassword,
      createPassword,

      federatedSignIn,
    }),
    [
      currentUser,
      loading,
      error,
      setError,
      login,
      signup,
      logout,
      forgotPasswordRequest,
      forgotPasswordSubmit,
      changePassword,
      createPassword,
      federatedSignIn,
    ]
  );

  return (
    <CognitoContext.Provider value={value}>{children}</CognitoContext.Provider>
  );
};

export const useCognito = () => useContext(CognitoContext);
