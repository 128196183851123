import { Link } from 'react-router-dom';
import { useCognito } from '../../../providers/cognito.provider';
import { AuthRoutesPaths } from '../../../constants';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import React from 'react';

export function AuthLayout({
  title,
  headline,
  error,
  children,
  hideSocial,
}: {
  title: string;
  headline: JSX.Element;
  error: string;
  children?: JSX.Element;
  hideSocial?: boolean;
}) {
  return (
    <main className="flex min-h-full overflow-hidden pt-16 sm:py-28">
      <div className="mx-auto flex w-full max-w-2xl flex-col px-4 sm:px-6">
        <a aria-label="Home" href="/" className="m-auto flex">
          {/* <Logo height={60} /> */}
          <span className="self-center ml-2 text-gray-600 text-lg md:text-2xl font-semibold whitespace-nowrap dark:text-white">
            Tali
          </span>
        </a>
        <div className="relative mt-12 sm:mt-16">
          <BackgroundCircles />
          <h1 className="text-center text-2xl font-medium tracking-tight text-gray-700">
            {title}
          </h1>
          <p className="mt-3 text-center text-lg text-gray-700">{headline}</p>
        </div>
        <div className="border-solid border-gray-300 p-10 border mx-4 mt-10 flex-auto text-gray-700 rounded-3xl shadow-2xl shadow-gray-900/10">
          <div className="text-center text-red-600">{error} </div>
          {!hideSocial && (
            <>
              <SocialAuthButtons />
              <Divider />
            </>
          )}
          {children}
        </div>
      </div>
    </main>
  );
}

function SocialAuthButtons() {
  const { loading, federatedSignIn } = useCognito();

  return (
    <div className="flex flex-col justify-center">
      <button
        className="px-6 py-2 mt-4 font-semibold text-gray-900 bg-gray-200 border-2 border-gray-500 rounded-md shadow outline-none hover:bg-blue-50 hover:border-blue-400 focus:outline-none"
        onClick={() => federatedSignIn(CognitoHostedUIIdentityProvider.Google)}
      >
        <GoogleIcon />
        Sign in with Google
      </button>
    </div>
  );
}

function GoogleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline w-4 h-4 mr-3 text-gray-900 fill-current"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
    >
      <path
        fill="#fbc02d"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
      <path
        fill="#e53935"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      ></path>
      <path
        fill="#4caf50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      ></path>
      <path
        fill="#1565c0"
        d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
    </svg>
  );
}

function Divider() {
  return (
    <div className="relative flex py-5 items-center">
      <div className="flex-grow border-t border-gray-400"></div>
      <span className="flex-shrink mx-4 text-gray-400">or</span>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  );
}

function BackgroundCircles() {
  return (
    <svg
      viewBox="0 0 1090 1090"
      aria-hidden="true"
      fill="none"
      preserveAspectRatio="none"
      width="1090"
      height="1090"
      className="absolute -top-7 left-1/2 -z-10 h-[788px] -translate-x-1/2 stroke-gray-300/30 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:-top-9 sm:h-auto"
    >
      <circle cx="545" cy="545" r="544.5"></circle>
      <circle cx="545" cy="545" r="480.5"></circle>
      <circle cx="545" cy="545" r="416.5"></circle>
      <circle cx="545" cy="545" r="352.5"></circle>
    </svg>
  );
}
