import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useCognito } from '../../../providers/cognito.provider';
import { AuthRoutesPaths } from '../../../constants';

import { AuthLayout } from '../common/AuthLayout';

export function ChangePasswordPage() {
  const { error, setError } = useCognito();

  useEffect(() => setError(''), []);

  const headline = <></>;
  return (
    <>
      <Helmet>
        <title>Tali - Change Password</title>
      </Helmet>
      <AuthLayout
        title="Change Password"
        headline={headline}
        error={error}
        hideSocial={true}
      >
        <ChangePasswordForm />
      </AuthLayout>
    </>
  );
}

function ChangePasswordForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { currentUser, loading, forgotPasswordSubmit, setError } = useCognito();

  const [state, setState] = useState({
    password: '',
    confirmedPassword: '',
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/stories');
    }
  }, [currentUser, loading]);

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = async (e: any) => {
    e.preventDefault();

    if (state.password !== state.confirmedPassword) {
      setError('The two passwords that you entered do not match!');
      return;
    }

    await forgotPasswordSubmit(
      searchParams.get('username')!,
      searchParams.get('resetCode')!,
      state.password
    ).then((response) => {
      // @ts-ignore
      if (response) {
        setPasswordChanged(true);
      }
    });
  };

  return (
    <section>
      <div className="w-full">
        {!passwordChanged && (
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmitClick}>
            <div>
              <label className="input input-bordered flex items-center gap-2">
                New Password
                <input
                  className='grow'
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  required={true}
                  value={state.password}
                  onChange={handleChange}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2">
                Retype New Password
                <input
                  className='grow'
                  type="password"
                  name="confirmedPassword"
                  id="confirmedPassword"
                  placeholder="••••••••"
                  required={true}
                  value={state.confirmedPassword}
                  onChange={handleChange}
                />
              </label>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-50"
              disabled={loading}
            >
              Submit
            </button>
          </form>
        )}

        {passwordChanged && (
          <>
            <div className="text-green-700">Password changed successfully!</div>

            <Link to={AuthRoutesPaths.Login} className="text-indigo-700">
              Go to login page
            </Link>
          </>
        )}
      </div>
    </section>
  );
}
