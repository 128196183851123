import { gql } from 'urql';

export const GetStoriesQuery = gql`
  query {
    stories {
      id
      title
      subjectDescription
      status
    }
  }
`;

export const GetStoryQuery = gql`
  query($id: String!) {
    story(id: $id) {
      id
      title
      subjectDescription
      status
    }
  }
`;

export const CreateStoryMutation = gql`
    mutation ($input: MutationCreateStoryInput!) {
        createStory(input: $input) {
            id
        } 
    }
`