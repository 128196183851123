import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCognito } from '../../../providers/cognito.provider';
import { AuthRoutesPaths } from '../../../constants';
import { AuthLayout } from '../common/AuthLayout';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export function SignupPage() {
  const { error, setError } = useCognito();
  const { t } = useTranslation();

  useEffect(() => setError(''), []);

  const headline = (
    <>
      {t('Already have an account')}?{' '}
      <Link to={AuthRoutesPaths.Login} className="text-indigo-300">
        {t('Sign in')}
      </Link>{' '}
      {t('to continue')}
    </>
  );
  
  return (
    <>
      <Helmet>
        <title>Tali - Signup</title>
      </Helmet>
      <AuthLayout
        title={t('Create a new account')}
        headline={headline}
        error={error}
      >
        <SignupForm />
      </AuthLayout>
    </>
  );
}

function SignupForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUser, loading, signup } = useCognito();

  const [state, setState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (currentUser) {
      navigate("/stories");
    }
  }, [currentUser, loading]);

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e: any) => {
    e.preventDefault();

    signup({
      username: state.email,
      password: state.password,
    });
  };

  return (
    <section>
      <div className="w-full">
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmitClick}>
          <div>
            <label className="input input-bordered flex items-center gap-2">
              Your Email
              <input
                className='grow'
                value={state.email}
                onChange={handleChange}
                placeholder="name@gmail.com"
                type="email"
                name="email"
                id="email"
                required={true}
              />
            </label>
          </div>
          <div>
            <label className="input input-bordered flex items-center gap-2">
              Password
              <input
                className='grow'
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                required={true}
                value={state.password}
                onChange={handleChange}
              />
            </label>

          </div>

          <button
            type="submit"
            className="w-full text-white mt-5 bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-50"
            disabled={loading}
          >
            {t('Create an account')}
          </button>
        </form>
      </div>
    </section>
  );
}
