import { Link } from "react-router-dom";
import { useCognito } from "../providers";
import { AuthRoutesPaths } from "../constants";
import { useTranslation } from "react-i18next";


export function HeaderMenu() {
    const { t, i18n } = useTranslation();

    const { currentUser } = useCognito();

    // const { data, loading: viewerLoading } = useGetViewerQuery({
    //     fetchPolicy: 'cache-only',
    //     onError: genericErrorHandler,
    //     skip: !currentUser?.getSignInUserSession(),
    // });

    return <div className="bg-secondary px-5">
        <div className="navbar max-w-screen-2xl m-auto">
            <div className="flex-1">
                <Link to="/" className="text-xl font-bold	">Tali</Link>
                <div className="mx-8">
                    <Link to="/story/list" className="flex items-center hover:text-blue-500 transition-colors">
                        Povesti
                    </Link>
                </div>

                <div>
                    <Link to="/story/create" className="flex items-center hover:text-blue-500 transition-colors">
                        Genereaza Poveste
                    </Link>
                </div>
            </div>
            <div className="flex-none">
                {!currentUser && (
                    <Link to={AuthRoutesPaths.Login}>
                        <button className="btn btn-primary mr-4">
                            {t('Log In')}
                        </button>
                    </Link>
                )}
                {!currentUser && (
                    <Link to={AuthRoutesPaths.Signup}>
                        <button className="btn btn-primary">
                            {t('Get Started')}
                        </button>
                    </Link>
                )}

                {currentUser &&
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img alt="Tailwind CSS Navbar component" src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                            </div>
                        </div>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                            <li>
                                <Link to={AuthRoutesPaths.Logout}>Deconecteza-te</Link>
                            </li>
                        </ul>
                    </div>
                }

            </div>
        </div>
    </div>
}
