import { useNavigate, Link } from 'react-router-dom';
import { useQuery } from 'urql';
import { GetStoriesQuery } from '../graphql';


export const ListStoriesPage = () => {
    const navigate = useNavigate();

    const [result,] = useQuery({
        query: GetStoriesQuery,
    });

    const { data, fetching, error } = result;

    if (fetching) return <div className="loader m-auto" />;
    if (error) return <p>Oh no... {error.message}</p>;

    return (
        <div>
            <p className="text-4xl mb-6 mt-10">
                Stories
            </p>
            <div className="grid grid-cols-4 gap-5">
                <div className="card bg-base-100 shadow-xl cursor-pointer" onClick={() => navigate("/story/create")}>
                    <figure><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#333" className="h-56 w-56">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg></figure>
                    <div className="card-body">
                        <div className="card-actions justify-center">
                            <button className="btn btn-secondary">Generate New Story</button>
                        </div>
                    </div>
                </div>

                {
                    data.stories.map((story: any, index: number) => (<Link to={`/story/${story.id}`}>
                        <div className="card bg-base-100 shadow-xl">
                            <figure><img
                                src="/image-placeholder.webp"
                                alt="story-image"
                            />
                            </figure>
                            <div className="card-body">
                                <p className="text-xl">{story.title}</p>
                                <p className="text-lg">{story.subjectDescription}</p>
                            </div>
                        </div >

                    </Link>
                    ))
                }
            </div>
        </div >

    );
};
