// TODO: don't load polyfill in Chrome
import AudioRecorder from 'audio-recorder-polyfill';
// @ts-ignore
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import React from 'react';
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ThemeProvider } from "@material-tailwind/react";
import { authExchange } from '@urql/exchange-auth';
import { Client, Provider, cacheExchange, fetchExchange } from 'urql';
import ReactDOM from 'react-dom/client';
import './i18n';
import './index.css';
import { PageLayout } from './components';
import { CreateStoryPage, ListStoriesPage, StoryPage, LoginPage, LogoutPage, SignupPage, ForgotPasswordPage, ChangePasswordPage, OAuthPage, LandingPage } from './pages';
import reportWebVitals from './reportWebVitals';
import { AuthRoutesPaths } from './constants';
import { CognitoProvider } from './providers';
import { getAllByTestId } from '@testing-library/react';

AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const getToken = async (): Promise<string | undefined> => {
  let session: CognitoUserSession | null = null;
  try {
    session = await Auth.currentSession();
  } catch (e) {
    // NOTE: if there is no current session, amplify throws a string
    // and then tries to handle it as an error object.
  }

  const idToken = session?.getIdToken();

  if (!idToken) {
    // when there's no session, we just omit Auth header and let API decide
    // if the user has a right to get data or not
    return undefined;
  }

  // Refresh token if it's not valid for the next minute.
  if (idToken.getExpiration() < Date.now() / 1000 + 60) {
    const refreshToken = session?.getRefreshToken()

    try {
      if (!refreshToken) {
        throw new Error('Invalid refresh token!');
      }

      const cognitoUser = await Auth.currentAuthenticatedUser();
      await new Promise((resolve, reject) => {
        cognitoUser?.refreshSession(refreshToken, (err: Error) => {
          if (err) reject(err);
          resolve(undefined);
        });
      });
    } catch (error) {
      // Logout if refresh fails.
      await Auth.signOut();
      throw error;
    }

    return getToken();
  }

  return idToken.getJwtToken();
};


export const apiClient = new Client({
  // url: 'http://localhost:3001/graphql',
  url: 'http://localhost:3001/graphql',
  exchanges: [cacheExchange,
    authExchange(async utils => {
      let token = await getToken();

      return {
        addAuthToOperation(operation) {
          if (!token) return operation;
          return utils.appendHeaders(operation, {
            Authorization: token,
          });
        },
        willAuthError: () => false,
        didAuthError: () => false,
        refreshAuth: async () => { },
      };
    }),
    fetchExchange],
});

root.render(
  // <React.StrictMode>
  <Provider value={apiClient}>
    <ThemeProvider>
      <BrowserRouter basename='/'>
        <CognitoProvider>
          <PageLayout>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path={AuthRoutesPaths.Login} element={<LoginPage />} />
              <Route path={AuthRoutesPaths.Logout} element={<LogoutPage />} />
              <Route path={AuthRoutesPaths.Signup} element={<SignupPage />} />
              <Route
                path={AuthRoutesPaths.ForgotPassword}
                element={<ForgotPasswordPage />}
              />
              <Route
                path={AuthRoutesPaths.ChangePassword}
                element={<ChangePasswordPage />}
              />

              <Route path="oauth/token" element={<OAuthPage />} />
              <Route path="/story/list" element={<ListStoriesPage />} />
              <Route path="/story/create" element={<CreateStoryPage />} />
              <Route path="/story/:id" element={<StoryPage />} />
            </Routes>
          </PageLayout>
        </CognitoProvider>
      </BrowserRouter >

    </ThemeProvider>
  </Provider >

  // </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
