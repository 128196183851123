import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { GetStoryQuery } from '../graphql';

export const StoryPage = () => {
    const { id } = useParams();

    const [result,] = useQuery({
        query: GetStoryQuery,
        variables: { id },
    });

    const { data, fetching, error } = result;

    if (fetching) return <div className="loader m-auto" />;
    if (error) return <p>Oh no... {error.message}</p>;

    const { story } = data;

    if (story?.status === "InProgress") {
        return <div className='flex h-screen'>
            <div className='m-auto'>
                <div className="loader m-auto mt-[-150px] mb-10" />
                <div className='text-bold text-2xl'>Povestea este in curs de generare...</div>
            </div>
        </div>
    }

    return <div>Story
        <h2>{story?.title}</h2>
        <p>{story?.subjectDescription}</p>

        <h2>{story?.status}</h2>

    </div>
};