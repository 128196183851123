import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { gql, useMutation } from 'urql';
import { languages, chatModelNames } from "../constants";
import { Selector } from "../components";
import { CreateStoryMutation } from '../graphql';


export const CreateStoryPage = () => {
    const [formError, setFormError] = useState('');
    const navigate = useNavigate();

    const [settings, setSettings] = useState<any>({
        language: languages[0],
        // chatModelName: chatModelNames[0],
        subjectDescription: '',
        title: '',
        mainCharacterDescription: '',
        moral: '',
    });

    const [result, executeMutation] = useMutation(CreateStoryMutation);

    const { fetching, error } = result;
    if (fetching) return <div className="loader m-auto" />;
    if (error) return <p>Oh no... {error.message}</p>;

    const generateStoryHandler = () => {
        if (!settings.subjectDescription) {
            setFormError("Completeaza campurile obligatorii");
            return;
        }

        executeMutation({
            input: {
                ...settings,
                language: settings.language.id,
            }
        }).then((res: any) => {
            navigate(`/story/${res.data?.createStory.id}`)
        });
    };

    return <div className="max-w-screen-sm m-auto">
        <div className="mt-12 mb-12 text-4xl ">Genereaza o poveste noua</div>

        <div className="mt-6 grid grid-cols-1 lg:grid-cols-1 gap-4 text-left">
            <div className="w-full">
                <div className="label-text mb-1">* Povestea este despre</div>
                <textarea
                    className="textarea w-full bg-white textarea-bordered"
                    rows={4}
                    placeholder="Ex: O călătorie inițiatică a unui tânăr prin lumi fantastice, în căutarea adevăratului său destin."
                    value={settings.subjectDescription}
                    onChange={event => setSettings({ ...settings, subjectDescription: event.target.value })}
                />
            </div>
            <div>
                <div className="label-text mb-1">Titlul povestii este</div>
                <input
                    type="text"
                    placeholder="Ex: Voiaj în Necunoscut: Călătoria lui Eron"
                    className="input input-bordered w-full bg-white"
                    value={settings.title}
                    onChange={event => setSettings({ ...settings, title: event.target.value })}
                />
            </div>
            {/* <div className="mb-6 w-full m-auto">
                <Selector
                    label="Model Name"
                    option={settings.chatModelName}
                    options={chatModelNames}
                    onChange={(value: any) => setSettings({ ...settings, chatModelName: value })} />
            </div> */}
            <div>
                <div className="label-text mb-1">Descrie personajul principal</div>
                <textarea
                    className="textarea w-full bg-white textarea-bordered"
                    rows={2}
                    placeholder="Ex: Eron este un tânăr curajos și plin de resurse, cu o inimă mare și o dorință arzătoare de aventură. Părul său negru și ochii albaștri reflectă moștenirea sa misterioasă."
                    value={settings.mainCharacterDescription}
                    onChange={event => setSettings({ ...settings, mainCharacterDescription: event.target.value })}
                />
            </div>
            <div>
                <div className="label-text mb-1">Morala povestii este</div>
                <textarea
                    className="textarea w-full bg-white textarea-bordered"
                    rows={2}
                    placeholder="Ex: Adevărata forță vine din interior și, indiferent de provocările întâmpinate, perseverența și credința în sine sunt esențiale pentru a-ți realiza destinul."
                    value={settings.moral}
                    onChange={event => setSettings({ ...settings, moral: event.target.value })}
                />
            </div>
            <div>
                <Selector
                    label="Limba"
                    option={settings.language}
                    options={languages}
                    onChange={(value: any) => setSettings({ ...settings, language: value })} />
            </div>
        </div>

        {formError && <div role="alert" className="alert alert-error mt-8">
            <span>{formError}</span>
        </div>}

        <div className="mt-8">
            <button className="btn btn-secondary" onClick={generateStoryHandler}>Genereaza Poveste</button>
        </div>
    </div>;
};
