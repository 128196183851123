import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import React from 'react';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'Log In': 'Log In',
      'Get Started': 'Get Started',
      'Get Started for FREE': 'Get Started for FREE',
    },
  },
  ro: {
    translation: {
      'Log In': 'Intră în cont',
      'Get Started': 'Încearcă acum',
      'Get Started for FREE': 'Încearcă acum GRATUIT',

      // Auth Pages
      'Don’t have an account': 'Nu ai cont',
      'Sign up': 'Creează un cont',
      now: 'acum',
      'Sign in to account': 'Intră în cont',
      'Sign in': 'Intră în cont',
      'Forgot password': 'Ai uitat parola',
      'Already have an account': 'Ai deja un cont',
      'to continue': 'ca să continui',
      'Create a new account': 'Crează un cont nou',
      'Create an account': 'Crează un cont nou',

    },
  },
};

let lng = process.env.REACT_APP_LANGUAGE || 'ro';

console.log({ lng });

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    lng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
