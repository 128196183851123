import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useCognito } from '../../../providers/cognito.provider';
import { AuthRoutesPaths } from '../../../constants';
import { LoadingScreen } from '../../../components/loading-screen';

const COGNITO_ERRORS = {
  googleUsernameAlreadyExists: 'Already found an entry for username',
  errorPrefix: 'PreSignUp failed with error ',
};

export const OAuthPage: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { currentUser, federatedSignIn } = useCognito();

  // redirect if user already signed in
  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const error = query.get('error_description');

  useEffect(() => {
    if (error) {
      if (error.startsWith(COGNITO_ERRORS.googleUsernameAlreadyExists)) {
        // HACK: When the google identity is linked with an exiting cognito user, the request to Cognito
        // fails with this error. We have to retry the oauth flow.
        federatedSignIn(CognitoHostedUIIdentityProvider.Google);
      } else {
        // Remove cognito trigger prefix from error.
        // TODO: display error
        // message.error(error.replace(COGNITO_ERRORS.errorPrefix, ''));
        navigate(AuthRoutesPaths.Login);
      }
    }
  }, [error, navigate, federatedSignIn]);

  return <LoadingScreen />;
};
