import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useCognito } from '../../../providers/cognito.provider';
import { AuthRoutesPaths } from '../../../constants';

import { AuthLayout } from '../common/AuthLayout';

export function ForgotPasswordPage() {
  const { error, setError } = useCognito();

  useEffect(() => setError(''), []);

  const headline = (
    <>
      Enter your email address and we’ll send you a link to reset your password.
    </>
  );
  return (
    <>
      <Helmet>
        <title>Tali - Reset Password</title>
      </Helmet>
      <AuthLayout
        title="Reset Password"
        headline={headline}
        error={error}
        hideSocial={true}
      >
        <ForgotPasswordForm />
      </AuthLayout>
    </>
  );
}

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const [forgotPasswordRequested, setForgotPasswordRequested] = useState(false);
  const { currentUser, loading, forgotPasswordRequest } = useCognito();

  const [state, setState] = useState({
    email: '',
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/stories');
    }
  }, [currentUser, loading]);

  const handleChange = (e: any) => {
    const { id, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = async (e: any) => {
    e.preventDefault();

    await forgotPasswordRequest(state.email).then((response) => {
      // @ts-ignore
      if (response) {
        setForgotPasswordRequested(true);
      }
    });
  };

  return (
    <section>
      <div className="w-full">
        {!forgotPasswordRequested && (
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmitClick}>
            <div>
              <label className="input input-bordered flex items-center gap-2">
                Your Email
                <input
                  className="grow"
                  value={state.email}
                  onChange={handleChange}
                  placeholder="name@gmail.com"
                  type="email"
                  name="email"
                  id="email"
                  required={true}
                />
              </label>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-50"
              disabled={loading}
            >
              Submit
            </button>
          </form>
        )}

        {forgotPasswordRequested && (
          <div className="text-green-700">
            Instructions to reset your password will be sent to you. Please
            check your email.
          </div>
        )}
      </div>
    </section>
  );
}
