
import { HeaderMenu } from './header-menu';

export const PageLayout = ({ children }: any) => {
    return <div>
        <HeaderMenu />
        <div className="max-w-screen-xl m-auto text-center p-4">
            {children}
        </div>
    </div>
}